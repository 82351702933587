import _fillRange from "fill-range";
var exports = {};
var fill = _fillRange;

exports = function expandRange(str, options, fn) {
  if (typeof str !== "string") {
    throw new TypeError("expand-range expects a string.");
  }

  if (typeof options === "function") {
    fn = options;
    options = {};
  }

  if (typeof options === "boolean") {
    options = {};
    options.makeRe = true;
  } // create arguments to pass to fill-range


  var opts = options || {};
  var args = str.split("..");
  var len = args.length;

  if (len > 3) {
    return str;
  } // if only one argument, it can't expand so return it


  if (len === 1) {
    return args;
  } // if `true`, tell fill-range to regexify the string


  if (typeof fn === "boolean" && fn === true) {
    opts.makeRe = true;
  }

  args.push(opts);
  return fill.apply(null, args.concat(fn));
};

export default exports;